<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCard class="p-4">
            <CCardBody>
              <CForm @submit.prevent="changePassword">
                <h1>Change Password?</h1>
                <p class="text-muted">
                  Please Enter Your New Password.
                </p>
                <CInput
                  placeholder="New Password"
                  autocomplete="confirm password"
                  v-model.trim="password"
                  type="password"
                >
                  <template #prepend-content
                    ><CIcon name="cil-lock-locked"
                  /></template>
                </CInput>
                <CInput
                  placeholder="Confirm Password"
                  autocomplete="confirm password"
                  v-model.trim="password_confirmation"
                  type="password"
                >
                  <template #prepend-content
                    ><CIcon name="cil-lock-locked"
                  /></template>
                </CInput>

                <CRow>
                  <CCol col="6" class="text-left">
                    <CButton
                      color="primary"
                      class="px-4"
                      type="submit"
                      :disabled="loading || !formIsValid"
                    >
                      <span v-if="loading"
                        ><loader-icon
                          size="1.5x"
                          class="custom-class"
                        ></loader-icon
                        >Please Wait</span
                      >
                      <span v-else>Change Password</span>
                    </CButton>
                  </CCol>
                  <CCol col="6" class="text-right">
                    <CButton color="link" class="px-0" :to="{ name: 'Login' }"
                      >Sign In?</CButton
                    >
                    <CButton
                      color="link"
                      class="d-lg-none"
                      href="https://my.onecentral.ng/agent/register"
                      target="_blank"
                      >Register now!</CButton
                    >
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { UserService } from "@/services/user.service";
import Swal from "sweetalert2";
export default {
  name: "ChangePassword",
  data() {
    return {
      password: "",
      password_confirmation: "",
      loading: false,
      validateResetPasswordOTPView: false,
      e: "",
    };
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    resetFormIsValid() {
      return this.email !== "";
    },
    formIsValid() {
      return this.password !== "" && this.password_confirmation !== "";
    },
  },
  methods: {
    async changePassword() {
      let data = {
        token: this.$store.state.changePasswordToken,
        password: this.password,
        password_confirmation: this.password_confirmation,
      };

      this.loading = true;
      try {
        const response = await UserService.finalizeResetPassword(data);
        this.loading = false;

        Swal.fire({
          title: "Success",
          text: response.response_message,
          icon: "success",
          timer: 3000,
          confirmButtonText: "Close",
          showConfirmButton: false,
        });
        this.$router.push({ name: "Login" });
      } catch (error) {
        this.loading = false;
        console.log(error.response);
        let err = error.response.data;
        // let errorBag = {
        //   code: err.response_code,
        //   message: err.response_message,
        //   data: err.data,
        // };
        Swal.fire({
          title: "Error!",
          text: err.data,
          icon: "error",
          confirmButtonText: "Close",
        });
        setTimeout(() => this.$router.push({ name: "ResetPassword" }), 2000);
      }
    },
  },
};
</script>
